import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import CleanHTML from '../cleanHTML';

const sizing = {
  borderRadius: '4px',
  margin: '1rem auto',
  maxWidth: '512px',
};

/**
 * TalkingCAHPS page Hero component.
 * @component
 *
 * @returns {component} - <Hero title={string} body={string} />
 */

const Hero = ({ title, heroImage, body, col = false }) => (
  <div
    className={cx(
      base.flex,
      base.flexColumn,
      base.itemsCenter,
      base.justifyBetween,
      base.mb3,
      base.w100,
      main.memberWrap,
      main.memberWrapShow
    )}
  >
    <h2
      className={cx(
        base.mb3,
        main.memberTitle,
        main.heading,
        main.headingShowcase
      )}
      data-col={col}
    >
      <CleanHTML html={title} />
    </h2>
    {heroImage && heroImage.fluid ? (
      <Img
        fluid={heroImage.fluid}
        alt=""
        aria-hidden="true"
        style={sizing}
        className={base.w100}
      />
    ) : null}
    <CleanHTML html={body} />
  </div>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  heroImage: PropTypes.object,
  body: PropTypes.string.isRequired,
  col: PropTypes.bool,
};

Hero.defaultProps = {
  heroImage: {},
  col: false,
};

export default Hero;
