import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import validator from 'validator';
import { NetlifyForm, Honeypot } from 'react-netlify-forms';
import { event } from 'react-ga';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

/**
 * TalkingCAHPS page Form component.
 * @component
 *
 * @returns {component} - <Form inputs={string[]} btn={string} />
 */

const Form = ({ inputs, btn, confirmation, formAlt }) => {
  // eslint-disable-next-line no-unused-vars
  const [formName, setFormName] = useState(formAlt || 'Talking CAHPS');
  const [sent, setSent] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const validateEmail = evt => {
    const email = evt.target.value;

    if (email === '' || validator.isEmail(email)) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSent(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (sent) {
      event({
        category: 'Form',
        action: 'Submission',
        label: formName,
      });
    }
  }, [sent]);

  return (
    <div
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100
      )}
    >
      <NetlifyForm
        name={formName}
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyStart,
          base.w100,
          main.contactFormShowcase
        )}
        honeypotName="bot-field"
      >
        {({ handleChange, success, submitting }) => {
          if (success) {
            setSent(true);
          }

          return sent ? (
            <div
              className={cx(
                base.flex,
                base.flexColumn,
                base.itemsCenter,
                base.justifyCenter,
                base.w100,
                main.contactFormWrap
              )}
            >
              <p
                data-testid="confirmation"
                className={cx(
                  base.ma0,
                  base.tc,
                  base.w100,
                  main.contactFormConfirmation,
                  theme.colorTextGrey,
                  theme.title2
                )}
              >
                {confirmation}
              </p>
            </div>
          ) : (
            <>
              <Honeypot />
              {inputs.map(input => {
                if (input) {
                  const item = `${input}`.toLowerCase();
                  const isEmail = item.match(/email|e-mail/gi)?.[0];
                  const id = isEmail
                    ? 'email'
                    : input.toLowerCase().replace(/\s/g, '-');

                  return (
                    <div
                      key={id}
                      className={cx(
                        base.flex,
                        base.flexColumn,
                        base.itemsStart,
                        base.justifyStart,
                        base.mr3,
                        base.relative,
                        base.w100,
                        main.contactFormInnerShow
                      )}
                    >
                      <label
                        htmlFor={id}
                        className={cx(
                          base.mb1,
                          main.srh,
                          theme.colorTextGrey,
                          theme.title4
                        )}
                      >
                        {id}
                      </label>
                      <input
                        id={id}
                        data-testid={id}
                        className={cx(
                          base.w100,
                          main.formInputField,
                          main.formInputFieldTall
                        )}
                        type="text"
                        name={id}
                        inputMode={isEmail ? 'email' : 'text'}
                        required
                        placeholder={input}
                        onChange={evt => {
                          handleChange(evt);
                          if (isEmail) validateEmail(evt);
                        }}
                        data-valid={`${isEmail ? !invalidEmail : true}`}
                      />
                      {isEmail && (
                        <span
                          className={cx(
                            main.emailValidationError,
                            main.emailValidationErrorTall
                          )}
                          aria-hidden={!invalidEmail}
                          style={{ display: invalidEmail ? 'block' : 'none' }}
                        >
                          Invalid Email
                        </span>
                      )}
                    </div>
                  );
                }

                return null;
              })}
              <button
                type="submit"
                data-testid="submit"
                className={cx(
                  base.w100,
                  main.button,
                  main.contactButton,
                  main.contactButtonSm,
                  theme.colorWhite,
                  theme.dpBtnBlue
                )}
                disabled={submitting || sent || invalidEmail}
              >
                {btn}
              </button>
            </>
          );
        }}
      </NetlifyForm>
    </div>
  );
};

Form.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.string).isRequired,
  btn: PropTypes.string.isRequired,
  confirmation: PropTypes.string.isRequired,
  formAlt: PropTypes.string,
};

Form.defaultProps = {
  formAlt: '',
};

export default Form;
