import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import Form from './talkingCahpsForm';
import Hero from './talkingCahpsHero';

const sizing = {
  bottom: 0,
  position: 'absolute',
  top: 0,
  zIndex: -1,
};

/**
 * TalkingCAHPS page Main component.
 * @component
 *
 * @returns {component} - <Main />
 */

const Main = ({ data, formAlt, col = false, short = false }) => {
  const {
    background,
    confirmationMessage,
    header,
    heroImage,
    subtext: {
      childMarkdownRemark: { html },
    },
    inputBtn,
    firstInput,
    lastInput,
    healthPlanInput,
    inputPlaceholder,
  } = data;

  return (
    <section
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.contactMainShowcase,
        {
          [main.contactMainShowcaseClean]: col,
        }
      )}
    >
      {background && background.fluid ? (
        <Img
          fluid={background.fluid}
          alt=""
          aria-hidden="true"
          style={sizing}
          className={base.w100}
        />
      ) : null}
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyStart,
          base.w100,
          main.contactInner,
          main.contactInnerShowcase
        )}
        data-col={col}
        data-short={short}
      >
        <Hero title={header} heroImage={heroImage} body={html} col />
        <Form
          inputs={[firstInput, lastInput, healthPlanInput, inputPlaceholder]}
          btn={inputBtn}
          confirmation={confirmationMessage.childMarkdownRemark.rawMarkdownBody}
          formAlt={formAlt}
        />
      </div>
    </section>
  );
};

Main.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  formAlt: PropTypes.string,
  col: PropTypes.bool,
  short: PropTypes.bool,
};

Main.defaultProps = {
  formAlt: '',
  col: false,
  short: false,
};

export default Main;
